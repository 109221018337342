<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="content">
      <router-link :to="{ path: '../../../call_center/serviceLook' }">
        <el-button style="padding: 6px 32px">返回</el-button></router-link
      >
      <el-table :data="tableData" style="width: 100%; margin-top: 1.5%">
        <el-table-column prop="alarmNo" label="报警编号" width="170">
        </el-table-column>
        <el-table-column
          prop="imei"
          label="设备号码(IMEI)"
          width="150"
        ></el-table-column>
        <el-table-column prop="productName" label="产品名" width="130">
        </el-table-column>
        <el-table-column prop="memberName" label="使用者昵称" width="100">
        </el-table-column>
        <el-table-column prop="adminPhone" label="家庭管理员手机" width="120">
        </el-table-column>
        <!-- <el-table-column prop="urgentPhone" label="紧急联系人电话">
        </el-table-column> -->
        <el-table-column prop="createDate" label="发起时间" width="160">
        </el-table-column>
        <el-table-column prop="ymStatus" label="服务状态" width="90">
        </el-table-column>
        <el-table-column
          prop="memberSparePhone"
          label="联系手机"
          width="120"
        ></el-table-column>
        <el-table-column prop="alarmStatus" label="当前状态"> </el-table-column>
        <el-table-column
          prop="remark"
          label="备注说明"
          :show-overflow-tooltip="true"
          width="260"
        >
          <template slot-scope="scope">
            <div v-for="item in scope.row.orderStatusVOList" :key="item.status">
              <div style="text-align: left">
                <span style="margin-right: 5px">{{ item.updateDate }}</span>
                <span>{{ item.status }};</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cnoName" label="关联客服" width="120">
        </el-table-column>
      </el-table>
      <div class="bottom-left">
        <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next,jumper,sizes"
            :total="totalCount"
            @pagination="getData"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1,
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //列表查询
    getData() {
      apiShout
        .getHisLists({ pageCount: this.pageCount, pageSize: this.pageSize })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res, "查询列表返回数据");
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      console.log(this.pageSize, "padesize大小");
      this.getData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      console.log(this.pageCount);
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 30px 0px;
}
.bottom-left {
  margin-top: 2%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #f5f7fa;
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>